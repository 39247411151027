* {
  margin: 0px;
  padding: 0px;
}
.font-size-14 {
  font-size: 0.875rem;
}
p,
ul > li,
ol > li {
  font-size: 1.25rem;
}
img {
  max-width: 100%;
}
.pointer {
  cursor: pointer;
}
body {
  font-family: "Montserrat", Helvetica, sans-serif;
  height: 100vh;
  width: 100%;
  position: relative;
  background-image: url('paven.png');
  overflow-x: hidden;
}
.hide {
  display: none;
}
iframe {
  max-width: 100%;
  height: 360px;
  padding: 0 10px;
}
.quote {
  text-align: center;
  font-style: italic;
  line-height: 50px;
}
.clearfix {
  float: none;
  clear: both;
}
.text-500 {
  font-weight: 500;
}
.text-600 {
  font-weight: 600;
}
.bold {
  font-weight: 700;
}
.italic {
  font-style: italic;
}
.text-justify {
  text-align: justify;
}
.wrap-content {
  max-width: 1200px;
  margin: 0 auto;
}
.icons {
  width: 24px;
  color: white;
}
.no-underline {
  text-decoration: none;
}
.jumbotron {
  background: #2aa876;
  background: linear-gradient(90deg, #2aa876 0%, #0c4b33 100%);
  color: #fff;
  padding: 0 20px;
}
.purple {
  color: #553fb6 !important;
}
.btn-purple,
.bg-purple {
  background: #553fb6 !important;
  color: #fff !important;
}
.btn-purple:hover {
  background: #3e2b94 !important;
  color: #fff !important;
}
.btn-outline-purple {
  color: #553fb6 !important;
  border-color: #553fb6 !important;
}
.btn-outline-purple:hover {
  background: #3e2b94 !important;
  color: #fff !important;
}
.orange {
  color: #ff7c39;
}
.orange:hover {
  color: #fe4d3a;
}
.btn-orange,
.bg-orange {
  background: #ff7c39;
  color: #fff !important;
}
.btn-orange:hover {
  background: #fe4d3a;
  color: #fff !important;
}
.btn-outline-orange {
  color: #ff7c39;
  border-color: #ff7c39;
}
.btn-outline-orange:hover {
  background: #fe4d3a;
  color: #fff !important;
}
.django {
  color: #2aa876;
}
.django:hover {
  color: #0c4b33;
}
.btn-django,
.bg-django {
  background: #2aa876;
  color: #fff !important;
}
.btn-django:hover {
  background: #0c4b33;
  color: #fff !important;
}
.btn-outline-django {
  color: #2aa876;
  border-color: #2aa876;
}
.btn-outline-django:hover {
  background: #0c4b33;
  color: #fff !important;
}
#footer {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  margin: 150px auto 0;
  padding-top: 30px;
  overflow: hidden;
  background: #2aa876;
  color: #fff;
}
#footer .wrap-footer {
  max-width: 1100px;
  margin: 0 auto;
}
#footer .wrap-footer p {
  display: block;
  margin: 0px auto;
  color: #fff;
  text-shadow: 0px 0px 1px #ccc;
  font-size: 1.25rem;
  letter-spacing: 0.125rem;
}
#footer .wrap-footer ul {
  padding: 0;
}
#footer .wrap-footer ul li {
  width: 100%;
  list-style: none;
  margin: 10px auto;
  line-height: 22px;
}
#footer .wrap-footer ul li a {
  text-decoration: none;
  color: #fff;
  font-size: 0.875rem;
  line-height: 0.625rem;
  letter-spacing: 1px;
  transition: all 300ms;
}
#footer .wrap-footer ul li a:hover {
  color: #fff;
  transform: scale(1.1);
}
#footer .wrap-footer-text {
  margin: 0 auto;
  background: #0c4b33;
  padding: 10px;
}
#footer .wrap-footer-text h3 {
  text-align: center;
  color: #fff;
  text-shadow: 0px 0px 1px #ccc;
  font-size: 1rem;
  margin: 10px;
}
@media screen and (max-width: 1230px) {
  .wrap-footer {
    width: 1000px;
  }
}
@media screen and (max-width: 991px) {
  #footer {
    margin-top: 100px;
  }

  .ruta-sitio {
    text-align: left;
    margin-left: 14%;
  }

  .wrap-footer {
    width: 95%;
    display: block;
    margin: 0 auto;
    font-size: 1.4rem;
    text-align: center;
  }
  .wrap-footer ul li a,
.wrap-footer p {
    font-size: 1.2rem !important;
  }
}
@media screen and (max-width: 767px) {
  .ruta-sitio {
    text-align: center;
    margin-left: 0;
  }

  .wrap-footer {
    width: 95%;
    display: block;
    margin: 0 auto;
    font-size: 1.4rem;
    text-align: center;
  }
  .wrap-footer ul li a,
.wrap-footer p {
    font-size: 1.4rem !important;
  }

  #footer {
    margin-top: 60px;
  }
  #footer h3 {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 560px) {
  ul {
    padding: 5px !important;
  }
}